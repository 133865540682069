<template>
    <div class="content-index stock">
        <div style="height:calc(100vh - 200px);padding-bottom:20px;overflow:auto">
            <div
                    class="flex-row"
                    style="margin-bottom:20px;cursor:pointer"
                    @click="back"
            >
                <i class="el-icon-back" style="font-size:18px;color:#000"></i>
                <span style="padding-left:10px"
                ><span style="color:#8A9099">快速盘点</span> / 扫码盘点</span
                >
            </div>
            <div class="stock-top flex-between">
                <div class="flex-row">
                    <div>
                        <span style="margin-right:15px">已选 {{ selectList.length }}个</span>
                        <el-button size="medium" @click="removal">
                            批量移除
                        </el-button>
                    </div>
                    <div class="flex-row" style="margin-left: 50px">
                        <span style="width: 100px;">衣物码：</span>
                        <el-input
                                ref="query"
                                v-model.trim="outClothesNum"
                                placeholder="请输入或扫描衣物码"
                                class="setWidth"
                                @keyup.enter.native="handleClothes"
                        >
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="table-box">
                <Table
                        :page="true"
                        :button-width="100"
                        :header-style="headerStyle"
                        :select="true"
                        :height="''"
                        :orientation="'center'"
                        :serial-number="true"
                        :table-data="tableData"
                        :title-list="titleListData"
                        :loading="handleClothesLoading"
                        @operationClick="operationClick"
                        @selectChange="handleSelectionChange"
                >
                </Table>
            </div>
        </div>
        <div class="close-account flex-between">
            <div>
                <span>合计:</span>
                <span style="padding-left:10px">件数: {{ tableData.length }}</span>
            </div>
            <div class="flex-row">
                <span style="padding-left:20px">
          <el-button
                  @click="saveAndSubmit"
                  type="primary"
                  :loading="submitLoading"
          >
            扫码结束
          </el-button></span
                >
            </div>
        </div>
    </div>
</template>

<script>
import Table from "@/components/table2";
import {addFastCheck, checkClothesList, codeFastCheck} from "@/api/clothes/check";

export default {
    name: "stock",
    components: {
        Table,
    },
    beforeRouteLeave(to, form, next) {
        if (this.tableData.length != 0 && !this.submitTrue) {
            this.$confirm(
                "扫码衣物列表存在数据，是否留在本页面继续操作?离开将自动保存数据",
                "提示",
                {
                    confirmButtonText: "确定离开",
                    cancelButtonText: "我再看看",
                    type: "warning",
                }
            )
                .then(() => {
                    this.addFastCheck(1,next);
                })
                .catch(() => {});
        } else {
            next();
        }
    },
    data() {
        return {
            formData: {
                current: 1,
                size: 10,
            },
            tableData: [],
            titleListData: [
                {
                    prop: "clothesName",
                    label: "衣物名称",
                    width: 140,
                },
                {
                    prop: "outClothesNum",
                    label: "衣物号",
                    width: 120,
                },
                {
                    prop: "clothesStatusDesc",
                    label: "衣物状态",
                },
                {
                    prop: "storeName",
                    label: "归属门店",
                },
                {
                    prop: "factoryName",
                    label: "洗涤工厂",
                },
                {
                    prop: "storeBelongAnomalyFlag",
                    label: "门店归属异常",
                    type: 'slot'
                },
                {
                    prop: "clothesStatusAnomalyFlag",
                    label: "衣物状态异常",
                    type: 'slot'
                }
            ],
            total: 0,
            headerStyle: {
                background: "#f5f7fa",
                height: "50px",
            },
            dialogTableVisible: false,
            selectDialogVisible: false,
            tableList: [],
            selectList: [],
            totalNum: 0,
            totalPrice: 0,
            activityList: [],
            submitLoading: false,
            outClothesNum: '',
            checkId: '',
            submitTrue: false,
            handleClothesLoading: false
        };
    },
    mounted() {
        this.$nextTick(function() {
            this.$refs.query.focus();
        })
    },
    watch: {
        $route: {
            handler: function(value) {
                this.checkId = value.query.checkId ? value.query.checkId : null;
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.listWaitToCheck()
    },
    methods: {
        back() {
            this.$router.back();
        },
        listWaitToCheck() {
            if (this.checkId) {
                this.handleClothesLoading = true;
                checkClothesList(this.checkId )
                    .then(({data}) => {
                        if (data.code == 0) {
                            this.tableData = data.data;
                            this.handleClothesLoading = false;
                        } else {
                            this.tableData = [];
                            this.handleClothesLoading = false;
                        }
                    })
                    .catch(() => {
                        this.tableData = [];
                        this.handleClothesLoading = false;
                    });
            }
        },
        handleClothes() {
            this.handleClothesLoading = true;
            if (!this.outClothesNum) {
                this.handleClothesLoading = false;
                this.$message.error("请输入条码号");
                return;
            }
            let aa = this.tableData.find(
                (c) => c.outClothesNum === this.outClothesNum
            );
            if (aa) {
                this.outClothesNum = "";
                this.handleClothesLoading = false;
                this.$message.error("重复扫描");
                this.audio.btts("重复扫描");
                return;
            }
            this.handleClothesLoading = false;
            codeFastCheck({outClothesNum: this.outClothesNum}).then((response) => {
                console.log(response)
                if (response.data.code === 0) {
                    if (response.data.data.clothesStatusAnomalyFlag == 1) {
                        this.audio.btts("衣物状态异常");
                        this.$message.error("衣物状态异常");
                    } else if (response.data.data.storeBelongAnomalyFlag == 1) {
                        this.audio.btts("门店归属异常");
                        this.$message.error("门店归属异常");
                    } else {
                        this.audio.btts(response.data.data.clothesName);
                    }
                    this.outClothesNum = "";
                    this.tableData.unshift(response.data.data);
                } else {
                    this.outClothesNum = "";
                    this.$message.error(response.data.msg);
                    this.audio.btts(response.data.msg);
                }
            });
        },
        async addFastCheck(checkFlag,next) {
            this.handleClothesLoading = true;
            let data = {
                checkId: this.checkId,
                orderClothesFastCheckDetailList: this.tableData,
                checkFlag: checkFlag
            }
            let res = await addFastCheck(data);
            this.handleClothesLoading = false;
            console.log(res)
            if (res.data.code == 0) {
                if (checkFlag == 1) {
                    next();
                } else {
                    await this.$router.push({
                        path: "/home/more/fastCheck"
                    });
                }
            } else {
                this.$message.error(res.data.msg);
            }
            this.submitLoading = false;
        },
        // 批量移除
        removal() {
            if (this.selectList.length == 0) {
                this.$message.error("请勾选需要删除的衣物");
                return;
            }
            this.$confirm("是否移除当前衣物?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.tableList = [];
                    this.tableData = this.tableData.filter((item) => {
                        if (!this.selectList.includes(item.goodsId)) {
                            this.tableList.push({
                                goodsId: item.goodsId,
                                num: item.num,
                            });
                            return item;
                        }
                    });

                    this.$message({
                        type: "success",
                        message: "移除成功!",
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消移除",
                    });
                });
        },
        // 点击表格中的按钮触发
        operationClick({row, code}) {
            if (code == "remove") {
                this.$confirm("是否移除当前衣物?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.tableList = [];
                        this.tableData = this.tableData.filter((item) => {
                            if (item.goodsId != row.goodsId) {
                                this.tableList.push({
                                    goodsId: item.goodsId,
                                    num: item.num,
                                });
                                return item;
                            }
                        });
                        this.$message({
                            type: "success",
                            message: "移除成功!",
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消移除",
                        });
                    });
            }
        },
        // 勾选表格项触发
        handleSelectionChange(data) {
            this.selectList = data.map((item) => item.goodsId);
        },
        // 保存并提交
        saveAndSubmit() {
            if (this.tableData.length == 0) {
                this.$message.error("请扫码衣物");
                return;
            }
            this.submitLoading = true;
            this.submitTrue = true
            this.addFastCheck(2);
        },
    },
};
</script>

<style lang="scss" scoped>
.stock {
  padding: 20px;
  position: relative;

  .table-box {
    margin-top: 20px;
  }

  .icon {
    width: 4px;
    height: 15px;
    border-radius: 5px;
    background-color: #3370ff;
    margin-right: 10px;
  }

  .close-account {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100%;
    padding: 0 20px;
    border-top: 1px solid #ebeef5;
    background-color: #fff;
    z-index: 99;
  }
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  align-items: center;
}

::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
</style>
